.nimbus_text-view {
	.nimbus_text-view__label {
		font-weight: 600;
		font-size: 14px;
		color: #122a57;
        line-height: 1.5;
	}
	.nimbus_text-view__value {
		font-size: 14px;
		color: #5F6680;
	}
}
