.result-comp {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    padding: 32px;
    border-radius: 12px;
    &.result-comp--success {
      .icon {
        color: #53c41a;
      }
      .title {
        color: rgba(0, 0, 0, 0.85);
      }
    }
    &.result-comp--info {
      .icon {
        color: #1a3cc4;
      }
      .title {
        color: rgba(0, 0, 0, 0.85);
      }
    }
    &.result-comp--error {
      .icon {
        color: #f5212d;
      }
      .title {
        color: rgba(0, 0, 0, 0.85);
      }
    }
    .icon {
      font-size: 48px;
    }
    .title {
      font-size: 24px;
      font-weight: 500;
      line-height: 1.5;
    }
    .sub-title {
      font-size: 18px;
      text-align: center;
    }
    .extra {
      margin-top: 16px;
    }
  }
  