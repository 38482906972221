.collapsible-card-container {
	// box-shadow: 0px 4px 12px rgba(120, 120, 120, 0.11);
	border-radius: 15px;
	background-color: white;
	margin-bottom: 16px;
	.container__header {
		padding: 16px;
		border-bottom: 1px solid #ebebeb;
		// box-shadow: 0px 4px 12px rgba(120, 120, 120, 0.11);
		border-radius: 12px;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.header__left {
			.header__title {
				font-size: 16px;
				font-weight: 600;
				line-height: 1.5;
				color: #181818;
			}
			.header__desc {
				font-size: 15px;
				color: #5f5f5f;
			}
		}
		.header_right {
			display: flex;
			align-items: center;
			.edit-btn {
				cursor: pointer;
				background: #f2f5ff;
				border-radius: 48px;
				border: none;
				color: #0a3082;
				padding: 8px 16px;
			}
			.toggle_icon {
				font-size: 24px;
				height: 48px;
				width: 48px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
	.container__content {
		border-radius: 15px;
		overflow: hidden;
		height: 0px;
		transition: height 250ms cubic-bezier(0.4, 0, 0.2, 1);
	}
}
