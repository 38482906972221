.loan-step-content__send-link {
	background: white;
	box-shadow: 0px 4px 12px rgba(120, 120, 120, 0.11);
	border-radius: 15px;
	margin-bottom: 16px;
	padding: 36px 16px;

	.title {
		font-size: 16px;
		line-height: 16px;
        padding-bottom: 12px;
        font-weight: 500;
	}

	.desc {
		font-size: 14px;
		max-width: 300px;
		color: #929aac;
		margin: 0;
	}
	.action {
        margin-top: 36px;
	}
}
